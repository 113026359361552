import { BaseCmsComponent } from './base-cms.component';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';

@Component({ template: '' })
export abstract class ObservedComponent<T> extends BaseCmsComponent<T> implements AfterViewInit, OnDestroy {
  protected visible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected observer: IntersectionObserver;
  protected readonly unobserveWhenVisible: boolean = false;

  public override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setDataAttribute();
    this.visible$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.cd.markForCheck());
    this.initObserver();
  }

  protected initObserver(threshold: number = 0): void {
    if (this.isServer || typeof IntersectionObserver === 'undefined') {
      this.visible$.next(true);
      return;
    }

    this.observer = new IntersectionObserver(this.observeFn.bind(this), { threshold });
    this.observer.observe(this.el.nativeElement);
  }

  protected observeFn(entries: IntersectionObserverEntry[]): void {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.visible$.next(true);
        this.el.nativeElement.classList.add('lazy-loaded');

        if (this.unobserveWhenVisible) {
          this.observer.unobserve(entry.target);
        }
      } else {
        this.visible$.next(false);
      }
    });
  }

  protected setDataAttribute(): void {
    this.el.nativeElement.setAttribute('data-lazyloaded', true);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.observer?.disconnect();
  }
}
